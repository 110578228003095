import React from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";

import colors from '@styles/colors'

const Hero = loadable(() => import("@sections/about/Hero"));
const SEO = loadable(() => import("@components/SEO"));
const Header = loadable(() => import("@components/Header"))
const TedX = loadable(() => import("@sections/about/TedX"));
const Executives = loadable(() => import("@sections/about/Executives"));
const Directors = loadable(() => import("@sections/about/Directors"));
const Team = loadable(() => import("@sections/about/Team"));
const StandFor = loadable(() => import("@sections/about/StandFor"));
const Values = loadable(() => import("@sections/about/Values"));
const Locations = loadable(() => import("@sections/about/Locations"));
const PartnerCTA = loadable(() => import("@sections/about/PartnerCTA"));

type props = {
  data: any;
};

const About: React.FC<props> = ({ data }) => {

  return (
    <>
      <Header backgroundTrigger={"#about-hero"} startingTextColor={colors.white}/>
      <SEO title="About | mIQrotech" />
      <Hero />
      <TedX  />
      <Executives  executives={data.allContentfulExecutive.nodes} />
      <Directors  directors={data.allContentfulDirector.nodes} />
      <Team  />
      <StandFor  />
      <Values  />
      <Locations  />
      <PartnerCTA  />
    </>
  );
};

export default About;

export const query = graphql`
  query AboutPageQuery {
    allContentfulExecutive {
      nodes {
        fullName
        headshot {
          gatsbyImageData(
            formats: [WEBP, JPG]
            placeholder: BLURRED
            quality: 100
          )
        }
        linkedin
        order
        title
        bio {
          bio
        }
      }
    }
    allContentfulDirector {
      nodes {
        fullName
        linkedin
        order
        bio {
          bio
        }
      }
    }
  }
`;
